import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

// Installed
import { ArrowForwardIos, Close, Logout } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

// Components
import Logotype from './Logotype';

// Services
import ApiRequest from '../services/ApiRequest';

// Functions
import { DecodedToken } from '../functions/DecodedToken';

// Json
import json from '../assets/json/inputs.json';

// Css
import '../assets/css/hiddenmenu.css';

function HiddenMenu({ context, path }) {
    HiddenMenu.displayName = "Hidden Menu";

    const [categories, setCategories] = useState([]);

    const navigate = useNavigate();
    const user = DecodedToken();
    const access = user?.Roles.indexOf("support") > -1;
     
    useEffect(() => {
        async function getCategories() {
            await ApiRequest("category", "get").then(res => {
                setCategories(res.data);
            })
        }

        getCategories();

    }, [context.isOpenMenu])

    useEffect(() => {
        if (context.isOpenMenu)
            context.handleMenu();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])

    if (!context.isOpenMenu)
        return null;

    return (
        <div className='hidden-menu-container d-column ai-end'>
            <div className='hidden-menu swing-in-right-bck'>
                <div className='hm-header d-row jc-between'>

                    <Logotype css="hm-logotype"/>

                    <IconButton onClick={context.handleMenu}>
                        <Close />
                    </IconButton>
                </div>

                <div className='hm-wrapper d-column jc-between'>
                    <div className='hm-categories'>
                        {categories?.map((ctg, ind) => {
                            return <p className={`hm-category-link d-row jc-start${path.indexOf(ctg.keyword) > -1 ? " selected" : ""}`}
                                key={ind}
                                onClick={() => navigate(`/categories/${ctg.keyword}/surveys`)}>
                                <ArrowForwardIos /> {ctg.name}</p>
                        })}
                        <Button className="hm-add-category" size='small' color='inherit'
                            onClick={() => navigate("/new/category")} >Ny kategori</Button>

                        {(access ? json?.links : json?.links.filter(x => x.url !== "logs")).map((l, i) => {
                            return <p className={`hm-category-link d-row jc-start${path.indexOf(l.url) > -1 ? " selected" : ""}`}
                                key={i}
                                onClick={() => navigate(!!l?.link ? `/list/${l?.url}` : l?.url)}>
                                <ArrowForwardIos /> {l.label}</p>
                        })}
                    </div>


                    <p className='hm-logout d-row' onClick={() => navigate("/logout")}>
                        <Logout /> Logga ut</p>
                </div>
            </div>
        </div>

    )
}

export default HiddenMenu;
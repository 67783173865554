import React, { useEffect, useState } from 'react'

function Countdown({ deadline, reload, color = "inherit" }) {
    const [countdown, setCountdown] = useState(null);

    useEffect(() => {
        let daySeconds = 86400;

        let ds = deadline;
        let d = parseInt(ds / daySeconds);
        let h = parseInt((ds - (d * daySeconds)) / 3600)
        let m = parseInt((ds - ((d * daySeconds) + (h * 3600))) / 60);
        let s = ds - ((d * daySeconds) + (h * 3600) + (m * 60));

        const interval = setInterval(() => {
            s--;

            if (d === 0 && h === 0 && m === 0 && s <= 0) {
                clearInterval(interval);
                setCountdown(`00:00:00:00`);
                if (!!reload)
                    reload();
                return;
            }

            if (s < 0) {
                s = 59;
                m--;
                if (m < 0 && h > 0) {
                    m = 59;
                    h--;
                    if (h < 0 && d > 0) {
                        h = 23;
                        d--;
                    }
                }
            }

            let days = d > 0 ? `${d}${d > 1 ? " dagar" : " dag"}` : "";
            let hours = h < 10 || h <= 0 ? `0${h}` : h;
            let minutes = m < 10 || m <= 0 ? `0${m}` : m;
            let seconds = s < 10 || s <= 0 ? `0${s}` : s;
            setCountdown(`${days}&nbsp;&nbsp;&nbsp;${hours}:${minutes}:${seconds}`)
        }, 1000);

        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deadline])

    return (
        <span style={{ color: color }} dangerouslySetInnerHTML={{ __html: countdown }}></span>
    )
}

export default Countdown;
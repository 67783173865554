import { useEffect, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";

// Installed
import { ArrowDropDown, ArrowDropUp, ChecklistRtl, Close } from "@mui/icons-material";
import { Alert, Button, Collapse, IconButton, TextField } from "@mui/material";

// Components
import FormEmails from "./FormEmails";
import FormButtons from "./FormButtons";
import LinearLoading from "./LinearLoading";
import Modal from './Modal';
import DropdownList from "./DropdownList";

// Functions
import { ErrorHandle } from "../functions/ErrorHandle";

// Services
import ApiRequest from "../services/ApiRequest";
import FormFields from "./FormFields";

function FormSend() {
  FormSend.displayName = "FormSend";

  const { id, form, navigate, visible, changeSupplier } = useOutletContext();

  const savedResponse = localStorage.getItem("savedResponse");
  const savedMail = localStorage.getItem("savedMail");

  const [formData, setFormData] = useState({
    formId: id,
    emails: [],
    supplier: null,
    mailTitle: form.name,
    mailContent: savedMail,
    saveMail: !!savedMail,
    contacts: "",
    saveContacts: false,
    saveContactsDisabled: false,
    emailsKeyword: "",
    response: savedResponse,
    saveResponse: !!savedResponse,
    notification: false,
    notificationEmails: [],
    editable: false,
    deadline: ""
  });

  const [supplierForm, setSupplierForm] = useState({
    name: ""
  })


  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [suppliers, setSuppliers] = useState();
  const [viewSupplierForm, setViewSupplierForm] = useState(false);
  const [sending, setSending] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [supplierExists, setSupplierExists] = useState(false);
  const [response, setResponse] = useState(null);
  const [contactsName, setContactsName] = useState("");
  const [savedContacts, setSavedContacts] = useState(null);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const loc = useLocation();

  useEffect(() => {

    async function getDataToSend() {
      await ApiRequest("form/survey/data").then(res => {
        const { suppliers, name } = res?.data;
        setSuppliers(suppliers);
        // setEmails(receivers); receivers,
        setContactsName(name);

        if (!!name) {
          const saved = localStorage.getItem(name);
          setSavedContacts(saved);
          if (saved)
            setFormData({ ...formData, contacts: saved, saveContacts: true });
        }
        setTimeout(() => {
          setLoading(false);
        }, 500)
      })
    }

    changeSupplier();
    getDataToSend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    const d = formData;

    setDisabled(!d?.supplier || d?.emails?.length === 0 || d?.mailTitle?.length < 3
      || (d?.saveEmails && form.emailsKeyword?.length < 3) || (d.notification && (!d.notificationEmails || d.notificationEmails?.length === 0)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])


  const inputs = [
    { label: "E-post titel", name: "mailTitle", type: "text", placeholder: "Minst 3 tecken", required: true },
    {
      label: "E-post innehåll", name: "mailContent", type: "textarea",
      info: "Obs! Om e-post innehåll skickas tomt då blir bara länken i mejlet."
        + "<br/>Skriv <span style='color:#cc0000;font-weight:600'>{link}</span> i texten där du vill placera länken till enkäten, annars placeras länken längst ner efter texten som en knapp.",
      placeholder: "Din text här ..."
    },
    formData?.mailContent?.indexOf("{link}") > -1 && {
      label: "Ord eller mening som enkät länk", name: "linkWord", class: "blink-color", type: "text", placeholder: "Till exempel: 'här', 'öppna länken' eller andra ordet"
    },
    { label: "Spara e-post innehåll?", name: "saveMail", type: "checkbox", disabled: (!savedMail && formData?.mailContent?.length < 10) },
    {
      label: "Kontakt informtion i e-post", name: "contacts", type: "textarea", placeholder: "Dina kontakt uppgifter",
      minRows: 7, info: formData?.saveContacts ? "Notera! <ul><li>Kontaktinformation sparas i den aktuella webbläsaren för den här datorn och är endast tillgänglig för denna aktuella användare som sparar informationen.</li><li> Nya sparade informationen ersätter den gamla informationen.</li><li> Den lagrade informationen försvinner när webbläsaren rensas från cookies.</li></ul>" : null
    },
    { label: "Spara kontakt uppgifter?", name: "saveContacts", type: "checkbox", disabled: (!savedContacts && formData?.contacts?.length < 10) },
    { label: "Svarstext", name: "response", type: "textarea", placeholder: "Svarstext till kunden efter att enkäten har skickats.", minRows: 7 },
    { label: "Spara svarstext?", name: "saveResponse", type: "checkbox", disabled: (!savedResponse && formData?.response?.length < 4) },
    { label: "Redigerbar", name: "editable", type: "checkbox", info: "Enkätsvar får redigeras av deltagaren innan deadline" },
    { label: "Meddela via e-post när undersökningen är klar.", name: "notification", type: "checkbox" },
    formData?.notification && { label: "E-postadresser", name: "notificationEmails", class: "blink-color", placeholder: "Obs! Separera med kommatecken!", type: "text", required: formData.notification },
    { label: "Deadline", type: "datetime", name: "deadline", info: "Standard deadline är 10 dagar." }
  ];

  const onChange = (value) => {
    setFormData(value);
  }

  const handleReceiversChange = (value) => {
    setFormData({ ...formData, ...value });
  }

  const supplierChoiceHandle = (value) => {
    const selected = (formData?.supplier === value) ? null : value;

    setWarning(form?.suppliers?.indexOf(selected?.name) > -1);
    changeSupplier(selected?.name ?? "Ej angiven");
    setFormData({
      ...formData, supplier: selected
    })
  }

  const onSupplierFormChange = (e) => {
    let value = e?.target?.value;

    if (value?.length === 1)
      value = value?.toUpperCase();
    else if (value?.length > 1)
      value = value[0].toUpperCase() + value.slice(1);

    setSupplierExists(suppliers.some(x => x.name.toLowerCase() === value?.toLowerCase()));
    setSupplierForm({ ...supplierForm, name: value?.replace("  ", " ") });
  }

  const onSupplierSubmit = async (e) => {
    e.preventDefault();

    setSending(true);
    await ApiRequest("supplier", "post", supplierForm).then(res => {
      setSending(false);
      const { model } = res?.data;

      if (res.status === 200 && !!model) {
        setSuccess(true);
        let arr = suppliers;
        arr.push(model)
        setSuppliers(arr)
        setFormData({ ...formData, supplier: model });
        setViewSupplierForm(false);
        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      } else
        setResponse(ErrorHandle(res?.data, navigate))
    }, error => {
      setResponse(ErrorHandle(error, navigate));
      setLoading(false);
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    setLoadingMessage("enkät skickas");

    sessionStorage.setItem("formData", JSON.stringify(formData));
    const obj = formData;

    if (!obj?.supplier || obj.emails?.length === 0) return;

    if (obj.notificationEmails?.length > 0) {
      let emailsString = obj.notificationEmails?.replaceAll(" ", "");
      obj.notificationEmails = emailsString.split(",");
    }

    if (obj.saveContacts)
      localStorage.setItem(contactsName, obj.contacts);
    else
      localStorage.removeItem(contactsName);

    if (obj.saveMail)
      localStorage.setItem("savedMail", obj?.mailContent);
    else
      localStorage.removeItem("savedMail");

    if (obj.saveResponse)
      localStorage.setItem("savedResponse", obj?.response);
    else
      localStorage.removeItem("savedResponse");

    delete obj.notification;
    delete obj.saveContacts;
    delete obj.saveResponse;
    delete obj.saveMail;

    await ApiRequest("survey", "post", obj).then(res => {
      setSending(false);
      setResponse(res);
    }, error => {
      setResponse(ErrorHandle(error, navigate));
      setLoading(false);
    })
  }

  const clickHandle = () => {
    const link = response?.link;
    const url = !!link ? link : loc.pathname;

    if (!link) {
      setResponse(null);
      navigate("resend", { replace: true });
    }

    setTimeout(() => {
      navigate(url);
    }, 100)
  }

  const closeModal = () => {
    if (!response?.data && response?.status === 200)
      navigate("/");
    else
      setResponse(null);
  }

  const dropdownHandle = () => {
    setViewSupplierForm(!viewSupplierForm);
    setSupplierForm({ ...supplierForm, name: "" })
  }

  if (!!response)
    return <Modal
      error={response?.error}
      content={response?.msg}
      link={response?.link}
      buttonValue={!!response?.link ? "Redigera enkät" : "Skicka igen"}
      clickHandle={clickHandle}
      close={closeModal} />;

  if (sending && !viewSupplierForm)
    return <LinearLoading styles={{ height: "70%" }} msg={loadingMessage} />;

  return (
    <div className="send-form fade-in" style={{ display: (loading || !visible) ? "none" : "inherit" }}>

      <div className="d-row jc-end">
        <Button onClick={dropdownHandle} color={viewSupplierForm ? "error" : "inherit"}
          endIcon={viewSupplierForm ? <ArrowDropUp /> : <ArrowDropDown />}>
          {viewSupplierForm ? "Avbryta" : "Lägg till en ny leverantör"}
        </Button>
      </div>

      {/* Add new supplier form */}
      <Collapse in={viewSupplierForm} timeout="auto" unmountOnExit>
        <form className="post-form" onSubmit={onSupplierSubmit}>
          <TextField label="Leverantör namn"
            value={supplierForm?.name}
            required
            error={supplierExists}
            color={supplierExists ? "error" : ""}
            helperText={supplierExists ? "En leverantör med liknande namn finns redan." : ""}
            InputProps={{
              endAdornment: supplierForm?.name?.length > 0 ?
                <IconButton onClick={() => setSupplierForm({ ...supplierForm, name: "" })}>
                  <Close /></IconButton> : null
            }}
            onChange={onSupplierFormChange} />

          {/* Form buttons */}
          <FormButtons confirmation={true} value="Spara"
            loading={sending && viewSupplierForm} disabled={sending || !supplierForm.name || supplierExists} />
        </form>
      </Collapse>

      {/* Suppliers */}
      {suppliers && <div className="w100 box-none">
        <FormFields inputs={[{
          label: "Leverantör", name: "supplier", type: "autocomplete",
          required: true, variants: suppliers, param: "name"
        }]} initData={{ supplier: formData?.supplier }} onValueChange={supplierChoiceHandle} />
        {success && <div className="blink-color"></div>}
      </div>}

      {/* Warning alert */}
      {warning && <Alert variant="standard" color="warning" onClose={() => setWarning(false)}>
        Observera! Leverantör {formData?.supplier?.name} har redan valts innan.
      </Alert>}

      {/* Suppliers that have already been selected for this survey */}
      {form?.suppliers?.length > 0 &&
        <DropdownList
          label="Levarntörer som redan har valts ut tidigare"
          icon={<ChecklistRtl />}
          list={form?.suppliers}
          dropdown={true}
          selected={[formData?.supplier?.name]}
          onClickHandle={(value) => supplierChoiceHandle(suppliers?.find(x => x.name === value))}
        />}

      {/* Receivers */}
      <FormEmails label="Mottagare" type="email" update={handleReceiversChange} />

      {/* Form */}
      <form onSubmit={onSubmit} className="w100 box-none">
        <FormFields inputs={inputs} initData={formData} onFormChange={onChange} />
        <FormButtons confirmation={true} value="Skicka" reset={() => navigate(-1)} disabled={disabled} />
      </form>
    </div>
  )
}

export default FormSend;
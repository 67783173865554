import { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

// Installed
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

// Pages
import Home from "../pages/auth/Home";
import Section from "../pages/auth/Section";
import Logout from "../pages/auth/Logout";
import FormView from "../pages/auth/FormView";
import Survey from "../pages/oauth/Survey";
import SurveyView from "../pages/auth/SurveyView";
import FormPage from "../pages/auth/FormPage";
import ResponseView from "../pages/auth/ResponseView";
import WorkInProgress from "../pages/auth/WorkInProgress";
import SupportTools from "../pages/auth/SupportTools";

// Components
import ListView from "../components/ListView";
import FormPost from "../components/FormPost";
import FormSurvey from "../components/FormSurvey";
import FormSend from "../components/FormSend";
import SendSurveyLink from "../components/SendSurveyLink";
import Overview from "../components/Overview";

// Functions
import { DecodedToken } from "../functions/DecodedToken";
import { ErrorHandle } from "../functions/ErrorHandle";

// Storage
import { AuthContext } from "../services/AuthContext";
import ApiRequest from "../services/ApiRequest";

// Css
import '../assets/css/custom.css';
import '../assets/css/animation.css';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

function AuthRoutes() {

  const [updatePage, setUpdatePage] = useState(false);
  const [connection, setConnection] = useState();

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!connection)
      joinConnection();
   
    checkStatusOfService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const routes = [
    {
      index: true,
      path: "/",
      element: <Home update={updatePage} />
    },
    {
      path: "/list/:page",
      element: <Section />,
      routes: [
        {
          path: "",
          element: <ListView hidden={true} />
        }
      ]
    },
    {
      path: "view/:keyword/:id",
      element: <Overview api="form/question/by" link="/view/survey" />
    },
    {
      path: "support/tools",
      element: <SupportTools api="form/question/by" label="Sopport online verktyg" />
    },
    {
      path: "/categories/:key/:path",
      element: <Section update={updatePage} />,
      routes: [
        {
          path: "",
          element: <ListView api="form" />
        },
        {
          path: ":param",
          element: <ListView api="survey" />
        },
        {
          path: "new/form",
          element: <FormSurvey visible={true} />
        }
      ]
    },
    {
      path: "/",
      element: <FormView />,
      routes: [
        {
          path: "view/survey/:id",
          element: <FormPost disabled={true} numbers={true} classes=" view-form" />
        },
        {
          path: "send/survey/:id",
          element: <FormSend />
        },
        {
          path: "send/survey/:id/:mode",
          element: <FormSend />
        },
        {
          path: "edit/survey/:id",
          element: <FormSurvey redaction={true} visible={true} />
        },
        {
          path: "edit/survey/:id/:used",
          element: <FormSurvey redaction={true} visible={true} />
        }
      ]
    },
    {
      path: "new/category",
      element: <FormPage label="Ny kategori" api="category" />
    },
    {
      path: "edit/category/:paramId",
      element: <FormPage label="Kategori" api="category" keywords={"keyword"} />
    },
    {
      path: "session/expired",
      element: <Logout expired={true} />
    },
    {
      path: "customer/survey/:open/:modelId/:receiverId",
      element: <Survey />
    },
    {
      path: "customer/survey/:open/:modelId/:receiverId/:resend",
      element: <Survey />
    },
    {
      path: "categories/:category/survey/:id",
      element: <SurveyView api="survey" update={updatePage} />,
      routes: [
        {
          path: "responses/:email",
          element: <ResponseView />
        },
        {
          path: ":send",
          element: <SendSurveyLink />
        }
      ]
    },
    {
      path: "categories/:category/survey/archive/:id",
      element: <SurveyView hidden={true} api="survey/archive" />
    },    {
      path: "/service/in/progress",
      element: <WorkInProgress authContext={authContext} navigate={navigate} />
    },
    {
      path: "logout",
      element: <Logout />
    }
  ];

  const joinConnection = async () => {
    if (isLocalhost) {
      console.log(localStorage.getItem("token") || sessionStorage.getItem("token"))
      return;
    }
    // const url = "wss://" + window.location.host + "/dashboard";
    const url = "https://" + window.location.host + "/dashboard";
    const email = DecodedToken(authContext.token)?.Email;
    //        .withUrl(url,{
    //     skipNegotiation: true,
    //     transport: HttpTransportType.WebSockets
    // })
    try {
      const connection = new HubConnectionBuilder()
        .withUrl(url)
        .configureLogging(LogLevel.Information)
        .build();

      connection.on("ConnectionMessage", (res, message) => {
        console.log(message)
      });

      connection.on("UpdateSurvey", (user, msg) => {

        setUpdatePage(user !== email);
        setTimeout(() => {
          setUpdatePage(false);
        }, 2000);
      })

      connection.on("UpdateServiceStatus", (status, user, updated) => {
        if (status)
          navigate("/service/in/progress");
        else
          navigate("/");

        console.log(updated)

        authContext.updateServiceWorkStatus(status, user !== email);
      })

      connection.onclose(e => {
        setConnection();
      })

      await connection.start();
      await connection.invoke("JoinConnection", { connection: "survey", email: email });

      setConnection(connection);
    } catch (error) {
      console.error("Join connection => " + error);
    }
  }
  
  // Check the status of app service work
  async function checkStatusOfService() {
    await ApiRequest("app/status/of/service/work").then(res => {
      authContext.updateServiceWorkStatus(res?.data?.status, res.data.hide);
      if (!!res?.data.status && !!res.data.hide)
        navigate("/service/in/progress");
        // navigate("/service/in/progress", { state: { message: res.data } });
    }, error => console.warn(ErrorHandle(error, navigate)));
  }

  return <Routes>
    {routes.map((route, index) => {
      const { element, ...rest } = route;

      if (!!rest?.routes) {
        return <Route key={index} {...rest} element={element} >
          {rest?.routes?.map((children, ind) => {
            const { element, ...rest } = children;
            return <Route key={ind} {...rest} element={element} />;
          })}
        </Route>
      } else
        return <Route key={index} {...rest} element={element} />;
    })}

    {/* If route is no exists */}
    <Route path="/*" element={<Home />} />
  </Routes>
}

export default AuthRoutes;
import { useNavigate } from "react-router-dom";

// Installed
import { Poll } from "@mui/icons-material";

function Logotype({ css }) {

    const navigate = useNavigate();

    return (
        <div className={`d-row jc-between ai-end logotype ${css ?? ""}`} onClick={() => navigate("/")}>
            <Poll />
            <div className='d-column'>
                <p>Alvesta Kommun</p>
                <p className='logotype-subtitle'>
                    Enkät verktyg <span>för avtalsuppföljning</span>
                </p>
            </div>
        </div>
    )
}

export default Logotype;

import { useEffect } from "react";

// Installed
import { Button } from "@mui/material"; import {
    CheckBox, Description, Email, List, Numbers,
    RadioButtonChecked, Star, TextFields, TextFormat, Title, Tune
} from "@mui/icons-material";

// Json
import json from '../assets/json/inputs.json';

const imgs = {
    name: <Title color="error"/>,
    description: <Description />,
    text: <TextFormat />,
    number: <Numbers />,
    email: <Email />,
    textarea: <TextFields />,
    checkbox: <CheckBox />,
    radio: <RadioButtonChecked />,
    list: <List />,
    rating: <Star />,
    slider: <Tune />
};

function FormInputs({ build, drawn, editItem, disLabel, disDesc, handleClick, handleDrag }) {

    const inputs = json.form;
    useEffect(() => {
        if (!!editItem) {
            let drawnToUpdate = editItem;
            let input = inputs.find(x => x.type === drawnToUpdate?.type);
            drawnToUpdate.img = imgs[input.type];
            handleClick(drawnToUpdate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    const dragHandle = (e) => handleDrag(inputValue(e));

    const clickHandle = (e) => handleClick(inputValue(e));

    const inputValue = (e) => {
        let input = inputs?.find(x => x.type === e.target.value);
        input.img = imgs[input.type];
        return input;
    }

    return (
        <div className="input-variants d-column jc-start">
            {inputs.filter(x => !x.hidden)?.map((input, index) => {
                
                const disabled = (build && drawn?.type === input?.type) || (disLabel && input?.name !== undefined) 
                || (input?.item === "Title" && !disLabel) || (input?.value !== undefined && disDesc);

                return <Button key={index} draggable={true}
                    value={input?.type}
                    disabled={disabled}
                    color="inherit"
                    name="menu"
                    style={input?.margin ? { marginBottom: input?.margin } : null}
                    onDoubleClick={clickHandle}
                    onDragStart={dragHandle}>
                    {imgs[input.type]} {input.item}
                </Button>
            })}
        </div>
    )
}

export default FormInputs
// Installed
import { Alert } from "@mui/material";

function Response({ children, res, reset }) {
    
    const str = typeof res === "string";
    const error = str || res?.error;
    const msg = str ? str : res?.msg;
    const color = error ? "error" : (res?.color ?? "success");

    return <div className="w100">
        <div className={`response-box d-column ai-end w-100 ${color}`}>
            
            {/* Message */}
            <Alert color={color} variant="standard" severity={color} onClose={reset}>
                <p className="response-message" dangerouslySetInnerHTML={{ __html: msg ?? "Skickad!"}}></p>
            </Alert>

            {(!error && !!children) && children}
        </div>
    </div>
}

export default Response;
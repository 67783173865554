
import { useEffect } from "react";

// Css
import '../../assets/css/service.css';

function WorkInProgress({authContext, navigate}) {
  WorkInProgress.displayName = "WorkInProgress";
 
  useEffect(() => {
    if(!authContext.workInProgress)
      navigate(-1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className="d-row wh-100">
      <div className="d-column jc-end service-work">
        <p className="service-work-message w-100">Underhållsarbete pågår ...</p>
      </div>
    </div>
  )
}

export default WorkInProgress;
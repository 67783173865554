
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import Container from "../../components/Container";
import FormPost from "../../components/FormPost";
import Loading from "../../components/Loading";

// Functions
import { ErrorHandle } from "../../functions/ErrorHandle";

// Services
import ApiRequest from "../../services/ApiRequest";

// Json
import inputs from '../../assets/json/inputs.json';

function FormPage({ api, label, keywords }) {

    const { paramId } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState(null);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!paramId) return;

        setLoading(true);

        async function getById() {
            await ApiRequest(`${api}/${paramId}`).then(res => {
                setLoading(false);
                if (res?.status === 200)
                    setFormData(res.data);
                else
                    setResponse(`Ingen ${label.toLowerCase()} med matchande Id hittades`);

            }, error => {
                setLoading(false);
                setResponse(ErrorHandle(error, navigate));
            })
        }

        getById();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramId])

    return (
        <div className="view-wrapper fade-in">

            {/* Loading */}
            {loading && <Loading size={60} />}

            {!loading && <Container label={formData?.name || label}>
                {/* Post */}
                {!response && <div className="form-wrapper">
                    <FormPost api={paramId ? `${api}/${paramId}` : api} 
                              inputs={!!keywords ? inputs[api].filter(x => keywords?.indexOf(x.name) === -1) : inputs[api]}
                              hideLoading={true} resendModel={formData} />
                </div>}
            </Container>}

        </div>
    )
}

export default FormPage;


import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Installed

// Components
import Header from './components/Header';
import HiddenMenu from './components/HiddenMenu';

// Services
import AuthContextProvider, { AuthContext } from './services/AuthContext';

// Routes
import AppRoutes from './routes/AppRoutes';
import AuthRoutes from './routes/AuthRoutes';

// Css
import './assets/css/custom.css';

export default function App() {
  App.displayName = "App";

  return <AuthContextProvider>
    <Root />
  </AuthContextProvider>
}

function Root() {

  const [loading, setLoading] = useState(true);

  const authContext = useContext(AuthContext);
  const loc = useLocation();
  const { open } = useParams();

  useEffect(() => {

    localStorage.removeItem("devTeam");
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");
    if (!!token)
      authContext.authorize(token);
    else
      authContext.logout();


    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (authContext.isAuthorized)
        document.body.classList.add("bg");
    else
        document.body.classList.remove("bg");
}, [authContext.isAuthorized])

  return (
    <main>
      <div className='background'></div>
      {authContext.isAuthorized && <Header context={authContext} />}

      {!loading &&
        <div className="container d-column jc-start fade-in">

          {/* Routes */}
          {(!authContext.isAuthorized || !!open) ? <AppRoutes /> : <AuthRoutes />}

          {(authContext.isAuthorized && !open) && <HiddenMenu context={authContext} path={loc.pathname} />}
        </div>}
    </main>
  );
}
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

// Installed
import { Close, Construction, Home, Menu } from '@mui/icons-material';
import { Button } from '@mui/material';

// Components
import Logotype from './Logotype';

// Css
import '../assets/css/header.css';

function Header({ context }) {
    Header.displayName = "Header";

    const dLinks =
        [
            { icon: <Home />, url: "/" },
            { icon: context.isOpenMenu ? <Close color='error' /> : <Menu />, disabled: context.workInProgress }
        ]

    const [links, setLinks] = useState(dLinks);

    const navigate = useNavigate();
    const loc = useLocation();

    useEffect(() => {
        if (context.isOpenMenu)
            context.handleMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc])

    useEffect(() => {
        setLinks(dLinks);

        setTimeout(() => {
            if (context.workStatus)
                setLinks([{ icon: <Construction />, url: "/support/tools" }, ...dLinks]);
        }, 0)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context])

    const navigation = (url) => {
        if (!url) 
            context.handleMenu();
        else
            navigate(url);
    }

    return (
        <header className='header-container'>
            <div className="header-wrapper d-row jc-between">

                <Logotype />

                {/* Menu */}
                {context.isAuthorized && <div className='menu-wrapper d-row jc-end ai-end'>
                    {links.map((link, index) => {
                        return <Button
                            className={`menu-link${(loc.pathname === link?.url) ? " selected" : ""}`}
                            key={index}
                            disabled={link?.disabled}
                            onClick={() => navigation(link?.url)}>
                            {link.icon}
                        </Button>
                    })}
                </div>}
            </div>
        </header>
    )
}

export default Header;

import { InfoOutlined } from '@mui/icons-material'


export default function InfoBlock({visible, info}) {

  if(!info) return null;

  return (
    <div className={`d-row jc-start ai-start info-dropdown${(visible ? " visible" : "")}`}>
                <InfoOutlined fontSize="large" /> <span dangerouslySetInnerHTML={{ __html: info?.replace("\n", "<br/>") }}></span></div>
  )
}

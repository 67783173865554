// Installed
import { Route, Routes } from "react-router-dom";

// Pages
import Login from "../pages/oauth/Login";
import Survey from "../pages/oauth/Survey";

function AppRoutes() {
  const routes = [
    {
      index: true,
      path: "/",
      element: <Login />
    },
    {
      path: "customer/survey/:open/:modelId/:receiverId",
      element: <Survey />
    },
    {
      path: "customer/survey/:open/:modelId/:receiverId/:resend",
      element: <Survey />
    }
  ];


  return <Routes>
    {routes.map((route, index) => {
      const { element, ...rest } = route;
      return <Route key={index} {...rest} element={element} />;
    })}

    {/* If route is no exists */}
    <Route path="/*" element={<Login />} />
  </Routes>
}

export default AppRoutes;

const redirectUrl = window.location.href;

export const config = {
        auth: {
                clientId: "4a1c41a6-5a94-4499-98f3-d2788555d4d9",
                redirectUri: redirectUrl,
                scopes: ["api://4a1c41a6-5a94-4499-98f3-d2788555d4d9/survey"],
                authority: "https://login.microsoftonline.com/alvesta.onmicrosoft.com",
                postLogoutRedirectUri: redirectUrl,
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: true
        }
}
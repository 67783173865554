import { useEffect, useRef, useState } from "react";
import _ from "lodash";

// Installed
import { Cable, Close, Edit, Help, HelpOutline, Numbers, PieChart, Remove } from "@mui/icons-material";
import {
    Autocomplete, Checkbox, FormControlLabel, FormGroup, FormLabel, IconButton,
    MenuItem, Radio, RadioGroup, Rating, Select, Slider, TextField, TextareaAutosize, Tooltip
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InfoBlock from "./InfoBlock";

function FormFields({ children, inputs, keyIndex, scrollIndex, visible, initData, response,
    disabled, numbers, onFormChange, onValueChange }) {
    FormFields.displayName = "Form Fields";

    const [infoId, setInfoId] = useState(null);
    const [formData, setFormData] = useState(null);
    const [otherFields, setOtherFields] = useState([]);
    const [infos, setInfos] = useState([]);
    const [freeTexts, setFreeTexts] = useState();
    const [freeTextsIndexes, setFreeTextsIndexes] = useState([]);

    const refs = useRef([]);

    useEffect(() => {
        if (!initData || _.isEqual({}, initData)) return;

        // Update init data with other fields
        if (!initData.other) {
            initData.other = {};
            inputs.forEach(inp => {
                let ip = inp.iParams;
                if (!!ip && !!ip.variants && (ip.parameters?.indexOf("multiple") > -1 || inp?.type === "checkbox"))
                    initData.other[inp.name] = "";
            })
        } else {
            inputs.forEach(inp => {
                let ip = inp.iParams;
                if (!!initData.other[inp.name] ||
                    (!!ip && !!ip.variants && ip.variants.indexOf(!!initData[inp.name]) === -1))
                    setOtherFields(otherFields => [...otherFields, inp.name]);
            })
        }

        if (!!initData?.freeTexts) {
            Object.keys(initData?.freeTexts).forEach(key => {
                let index = Object.keys(initData).findIndex(x => x === key);
                if (index > -1)
                    setFreeTextsIndexes(freeTextsIndexes => [...freeTextsIndexes, index]);
            })

            setFreeTexts(initData.freeTexts);
        }

        setFormData(initData);

        // To open all info blocks
        inputs?.forEach((inp, ind) => {
            if (inp?.iParams?.info?.length > 0)
                setInfos(infos => [...infos, ind])
        });

        const ind = parseInt(scrollIndex);
        if (!!ind) {
            setTimeout(() => {
                refs?.current[ind]?.scrollIntoView();
                sessionStorage.removeItem("scrollIndex");
            }, 100)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!initData?.supplier || _.isEqual(initData?.supplier, formData?.supplier)) return;

        setFormData(initData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initData?.supplier])

    useEffect(() => {
        if (_.isEqual({}, formData)) return;

        if (!!onFormChange)
            onFormChange(formData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const showHideInfoBlock = (index, open) => {
        if (open)
            setInfos(infos?.filter(x => x !== index));
        else if (index === infoId)
            setInfoId(null);
        else
            setInfoId(index);
    }

    const handleChange = (e) => {
        const { target: { name, type, value } } = e;

        if (type === "radio")
            handleFieldOtherCheckboxChange(e, "radio");

        setFormData({
            ...formData, [name]: type === "checkbox" ? !formData[name] : value
        })
    }

    const handleNumberInputChange = (e) => {
        const value = e.target.value;

        if (!parseInt(value) && value !== "")
            return;

        setFormData({
            ...formData, [e.target.name]: parseInt(e.target.value) ?? 0
        })
    }

    const handleMultipleCheckboxChange = (e) => {
        const name = [e.target.name]
        let arr = formData[name] ?? [];

        if (typeof arr !== "object")
            arr = [];

        const value = e.target.value;
        const index = arr?.indexOf(value);

        if (index > -1)
            setFormData({ ...formData, [name]: arr?.filter(x => x !== value) });
        else {
            arr.push(value);
            setFormData({ ...formData, [name]: arr });
        }
    }

    const handleListChange = (e) => {
        const { target: { name, value } } = e;
        setFormData({ ...formData, [name]: value });
        if (!!onValueChange)
            onValueChange(e);

        setOtherFields(otherFields.filter(x => x !== name));
    }

    const handleFieldOtherCheckboxChange = (e, type, multiple = false) => {
        const { target: { name, value } } = e;

        const index = otherFields?.indexOf(name) > -1;
        if (index)
            setOtherFields(otherFields?.filter(x => x !== name));
        else if (value === "other")
            setOtherFields(otherFields => [...otherFields, name]);

        if (type !== "checkbox" && !multiple)
            setFormData({ ...formData, [name]: "" });

        if (index && (type === "checkbox" || multiple)) {
            let other = formData.other;
            other[name] = "";
            setFormData({ ...formData, other: other });
        }
    }

    const handleFieldOtherValueChange = (e, type) => {
        const { target: { name, value } } = e;

        if (type === "radio" || type === "list")
            setFormData({ ...formData, [name]: value });
        else {
            let other = formData.other;
            other[name] = value;
            setFormData({ ...formData, other: other });
        }
    }

    const handleFreeTextCheckboxChange = (ind) => {
        if (freeTextsIndexes?.indexOf(ind) > -1)
            setFreeTextsIndexes(freeTextsIndexes?.filter(x => x !== ind));
        else
            setFreeTextsIndexes(freeTextsIndexes => [...freeTextsIndexes, ind]);
    }

    const handleFreeTextsValueChange = (e) => {
        const { target: { name, value } } = e;
        const obj = freeTexts ?? {};
        obj[name] = value;
        setFreeTexts(obj);
        setFormData({ ...formData, freeTexts: obj });
    }

    const handleRatingChange = (e, name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    const handleDateTimeChange = (e, name) => {
        setFormData({
            ...formData, [name]: e.$d.toISOString()
        })
    }

    if (!formData || response) return null;

    let minus = 0;
    return inputs?.filter(x => !!x)?.map((item, ind) => {

        const iParams = item?.iParams;
        const info = iParams?.info ?? item?.info;
        const connected = iParams?.connectedTo;
        const column = iParams?.column;
        const params = iParams?.parameters;
        const multiple = params?.indexOf("multiple") > -1;
        const required = params?.indexOf("required") > -1;
        const fieldOther = params?.indexOf("fieldOther") > -1;
        const freeText = params?.indexOf("freeText") > -1;
        let number = (ind + 1) - minus;

        // Hide input if this connected to other input, until the expected response is entered
        if (!!connected && !visible) {
            const value = formData[connected?.name];
            const values = connected?.values ?? [];

            const $return = (connected?.definite && ((values?.length === 1 && value[0] !== values[0])
                || (typeof value === "object" && (value?.length !== values?.length || !value?.filter(v => values?.includes(v))))))
                || (typeof value === "number" && value !== parseInt(values[0]))
                || value?.length < parseInt(values[0]);

            if ($return) {
                minus++;
                return null;
            }
        }

        const index = keyIndex !== undefined ? keyIndex : ind;

        const variants = iParams?.pointsVariants ?? iParams?.variants ?? item?.variants;
        const lv = !!variants ? parseInt(variants[variants?.length - 1]) : null;
        const listObject = !!variants && typeof variants[0] === "object";
        const name = item?.name;
        const checkedVariants = !!variants && formData[name]?.length === variants?.length;
        const inpType = item?.type === "list" && multiple && !visible ? "autocomplete" : item?.type;
        const hiddenLabel = inpType === "checkbox" && !variants;
        let width = column > 0 ? { width: `calc((100% / ${column}) - 6px)`, margin: "0 3px 15px 3px" } : null;
        const otherField = otherFields.indexOf(name) > -1;

        let valueProps = multiple && Array.isArray(formData[name]) ?
            {
                defaultValue: formData[name],
                value: formData[name]
            }
            : (!multiple ? {
                defaultValue: _.isEqual({}, formData[name]) ? null : formData[name],
                value: (_.isEqual({}, formData[name]) ? null : formData[name])
            } : null);

        return <div className={`form-control${!item ? " none" : ""}`}
            key={index}
            ref={ref => refs.current[index] = ref}>

            {/* Label */}
            {(!hiddenLabel && item?.label) &&
                <div className={`d-row jc-between label-${visible ? "wrapper" : "view"}`}>
                    <FormLabel className="d-row jc-start label" required={required}>
                        {item?.label}
                    </FormLabel>

                    {/* Info icons */}
                    <div className="d-row row-wrapper">
                        {/* If this field was the last of changed */}
                        {parseInt(scrollIndex) === index && <Edit color="disabled" fontSize="medium" />}

                        {/* If field has an info */}
                        {!!info && <IconButton onClick={() => showHideInfoBlock(ind, infos?.indexOf(ind) > -1)}>
                            {(infoId === ind || infos?.indexOf(ind) > -1) ? <Close color="disabled" /> : <Help color="info" />} </IconButton>}

                        {/* If field included to diagram model */}
                        {(visible && params?.indexOf("diagram") > -1) && <Tooltip title="Svar i diagrammodell" classes={{
                            tooltip: "tooltip-info tooltip-multiline",
                            arrow: "tooltip-arrow-info"
                        }} arrow>
                            <PieChart color="primary" />
                        </Tooltip>}

                        {/* If thi field connected to other field */}
                        {(visible && !!connected) && <Tooltip
                            title={<span dangerouslySetInnerHTML={{
                                __html: `Kopplat<br/>Frågan: 
                                - ${connected?.label}${(connected?.value && connected?.definite) ? `<br/>Svaret: - ${connected?.value?.join(",")}` : ""}`
                            }}></span>}
                            classes={{
                                tooltip: "tooltip-info tooltip-multiline",
                                arrow: "tooltip-arrow-info"
                            }} arrow>
                            <Cable color="primary" />
                        </Tooltip>}
                    </div>
                </div>}

            {/* Info block */}
            {(!!info && inpType !== "checkbox") && <InfoBlock visible={(infoId === ind || infos?.indexOf(ind) > -1)} info={info} />}

            {/* Fields */}
            <div className={`fields-container${(inpType === "rating" && !visible) ? " rating" : ""} d-column jc-start`}>

                {/* Text input */}
                {["text", "password", "email"].indexOf(inpType) > -1 &&
                    <TextField
                        id={name}
                        name={name}
                        className={item?.class}
                        type={inpType}
                        disabled={disabled}
                        placeholder={item?.placeholder ?? ""}
                        autoComplete='off'
                        autoSave='off'
                        inputProps={{
                            maxLength: !!item?.max ? item?.max : 1000,
                            minLength: !!item?.min ? item?.min : 1
                        }}
                        value={formData[name] || ""}
                        required={required}
                        onChange={handleChange}
                    />}

                {/* Number input */}
                {inpType === "number" && <TextField
                    aria-label="number_input"
                    value={!!formData[name] ? formData[name] : 0}
                    placeholder={item?.placeholder ?? ""}
                    name={name}
                    autoComplete='off'
                    autoSave='off'
                    disabled={disabled}
                    onChange={handleNumberInputChange}
                />}

                {/* Textarea */}
                {(inpType === "textarea") && <TextareaAutosize
                    id={name}
                    placeholder={item?.placeholder ?? ""}
                    minRows={item?.minRows ?? 10}
                    name={name}
                    autoComplete='off'
                    autoSave='off'
                    disabled={disabled}
                    onChange={handleChange}
                    value={formData[name]?.replaceAll("<br/>", "\n") ?? ""}
                    required={required} />}

                {/* Checkbox */}
                {inpType === "checkbox" && <FormGroup
                    row={!column}
                    className={`d-row js-start r-wrap`}
                    style={hiddenLabel ? { marginTop: "25px" } : null}
                    name={name}
                    id="checkbox">

                    {/* One choice */}
                    {!iParams?.pointsVariants && <FormControlLabel
                        className={`d-row js-start${(!variants || variants?.length === 0) ? "" : " w-100"} single-checkbox`}
                        style={{ marginBottom: !variants ? "inherit" : "30px" }}
                        control={<Checkbox
                            id={name}
                            checked={!variants ? formData[name] : checkedVariants}
                            name={name}
                            aria-checked
                            required={(!variants || variants?.length === 0) && required}
                            disabled={item?.disabled || disabled}
                            value={!variants ? formData[name] : checkedVariants}
                            onChange={(e) => !variants ? handleChange(e)
                                : setFormData({ ...formData, [name]: checkedVariants ? [] : variants })} />}
                        label={<span style={{ minWidth: "max-content" }}>{(!variants || variants?.length === 0) ? item?.label : (variants?.length === 1 ? "" : "Markera alla")}</span>} />}

                    {/* Multiple variants */}
                    {!!variants && variants?.map((variant, i) => {
                        const v = <span dangerouslySetInnerHTML={{ __html: variant?.label ? `${variant?.label} ${!!visible ? `<span>${variant?.value} poäng<span>` : ""}` : variant }}></span>;
                        width = column > 0 ? { width: `calc((100% / ${column}) - 6px)`, margin: "0 3px 15px 3px" } : null;
                        return <FormControlLabel
                            key={i}
                            id={name + "_" + i}
                            style={width}
                            control={<Checkbox
                                id={name + "_" + i}
                                checked={!!formData[name] && formData[name]?.indexOf(variant?.label ?? variant) > -1}
                                name={name}
                                disabled={disabled}
                                value={variant?.label ?? variant} onChange={handleMultipleCheckboxChange} />} label={<span>{v}</span>} />
                    })}

                    {/* Info for checkbox */}
                    {(!!info && (!item?.label || hiddenLabel)) && <Tooltip title={info}
                        classes={{
                            tooltip: "tooltip-info",
                            arrow: "tooltip-arrow-info"
                        }} arrow>
                        <HelpOutline color="info" />
                    </Tooltip>}
                </FormGroup>}

                {/* Radio button */}
                {inpType === "radio" && <RadioGroup
                    aria-labelledby="radio-buttons-group"
                    row={!column}
                    className={`d-row js-start r-wrap labels`}
                    name={name}>
                    {((!required && !!formData[name]) ? variants.concat([""]) : variants)?.map((variant, i) => {
                        const v = <span dangerouslySetInnerHTML={{ __html: variant?.label ? `${variant?.label} ${!!visible ? `<span>${variant?.value} poäng<span>` : ""}` : variant }}></span>;
                        return <FormControlLabel
                            id={name + "_" + i}
                            key={i}
                            style={width}
                            onChange={handleChange}
                            value={variant?.label ?? variant}
                            control={<Radio
                                id={i.toString()}
                                color={(variant?.label ?? variant) !== "" ? "primary" : "error"}
                                checked={(variant?.label ?? variant) === formData[name]} />}
                            label={(variant?.label ?? variant) !== "" ? v : <Remove color="error" />} />
                    })}
                </RadioGroup>}

                {/* Autocomplete */}
                {(inpType === "autocomplete" && variants?.length > 0) && <Autocomplete
                    // key={index}
                    disablePortal
                    autoHighlight
                    fullWidth
                    autoSelect
                    disableCloseOnSelect={multiple} //
                    filterSelectedOptions //
                    freeSolo={false} //
                    options={variants}
                    id="combo-box-demo"
                    disabled={disabled}
                    multiple={multiple}
                    {...valueProps}
                    isOptionEqualToValue={(option, value) => {
                        return (multiple || !option?.[item?.param]) ? option === value : option?.[item?.param] === value?.[item?.param];
                    }}
                    // getOptionLabel={(option) => option?.[item?.param]}
                    getOptionLabel={(option) => !!option?.[item?.param] ? option?.[item?.param] : option}
                    onChange={(e, option) => {
                        setFormData({ ...formData, [name]: option });
                        if (!!onValueChange)
                            onValueChange(option);
                    }}
                    sx={{ minWidth: "100%" }}
                    renderInput={(params) => <TextField {...params} className={!!formData[name] ? "default-value" : ""}
                        autoComplete='off'
                        autoSave='off'
                        required={required && !formData[name] && !formData?.other[name]}
                        placeholder={formData[name]?.length > 0 ? "" : `Välj från listan`} />}
                />}

                {/* Select list */}
                {inpType === "list" && <Select
                    labelId="demo-multiple-name-label"
                    id={name}
                    name={name}
                    displayEmpty
                    fullWidth
                    renderValue={(value) => {
                        if (!value || value?.length === 0)
                            return <em>{item?.placeholder ?? "Välj från listan ..."}</em>;

                        return value;
                    }}
                    disabled={item?.disabled || disabled}
                    autoComplete='off'
                    autoSave='off'
                    required={otherField ? false : required}
                    defaultValue={formData[name]?.length > 0 ? formData[name] : ""}
                    value={(formData[name]?.length > 0 && !otherField) ? formData[name] : ""}
                    onChange={handleListChange}
                    // input={<OutlinedInput label=""/>}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 48 * 4.5 + 8,
                                width: 250,
                            },
                        }
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {variants?.map((l, vIndex) => {
                        const vl = variants.length.toString().length;
                        let nulls = vl === 4 ? "000" : (vl === 3 ? "00" : "0");

                        return <MenuItem key={vIndex} value={l}>
                            {(vl > 1 && (vIndex + 1).toString().length < vl) && nulls.slice((ind + 1).toString().length - 1)}
                            {vIndex + 1}. {listObject ? l?.name : l}
                        </MenuItem>
                    })}
                </Select>}

                {/* Slider */}
                {(inpType === "slider" && variants?.length > 0) && <FormGroup>
                    <Slider
                        aria-label="slider"
                        value={parseInt(formData[name]) ? parseInt(formData[name]) : 0}
                        valueLabelDisplay={parseInt(formData[name]) ? "on" : 'off'}
                        // getAriaValueText={sliderValueChange}
                        // step={item.count ?? (lv / 10)}
                        marks={[
                            { value: parseInt(variants[0]), label: `${variants[0]}${iParams?.sliderType ?? ""}` },
                            { value: lv, label: `${lv}${iParams?.sliderType ?? ""}` }
                        ]}
                        name={name}
                        disabled={disabled}
                        onChange={handleChange}
                        min={parseInt(variants[0])}
                        max={lv}
                    />
                </FormGroup>}

                {/* Rating */}
                {inpType === "rating" && <Rating
                    name={name}
                    value={parseFloat(formData[name]) ?? 0}
                    // precision={0.5}
                    precision={1}
                    max={parseInt(variants?.length)}
                    onChange={(e, newValue) => {
                        handleRatingChange(e, name, newValue);
                    }}
                />}

                {/* Additional variants */}
                {(fieldOther || freeText) && <div className="boxes-wrapper">
                    {/* Variant for field other */}
                    {(fieldOther && inpType !== "slider") && <div className="box-parameters">
                        <FormControlLabel id="checkbox" className="d-row checkbox-label"
                            onChange={(e) => handleFieldOtherCheckboxChange(e, inpType, multiple)}
                            name={name}
                            value="other"
                            control={inpType === "radio" ? <Radio checked={otherField} />
                                : <Checkbox checked={otherField} />}
                            label={<span>Annat</span>} />

                        {/* Hidden field other for free text radio, checkbox, slider and list */}
                        {(otherField && fieldOther) &&
                            <TextField
                                id={"other_" + name}
                                name={name}
                                className="w100 blink-color"
                                style={{ marginTop: "7px" }}
                                required={true}
                                autoComplete='off'
                                autoSave='off'
                                placeholder="Ditt svar här ..."
                                onChange={(e) => handleFieldOtherValueChange(e, inpType)}
                                value={formData?.other[name] ?? formData[name] ?? ""}
                            />}
                    </div>}

                    {/* Textfield for free texts */}
                    {freeText && <div className="box-parameters">
                        <FormControlLabel id="checkbox" className="d-row checkbox-label"
                            onChange={() => handleFreeTextCheckboxChange(ind)}
                            name={name}
                            value="FreeText"
                            // disabled={formData[name]?.length === 0 && inpType === "list"}
                            control={<Checkbox checked={freeTextsIndexes?.indexOf(ind) > -1} />}
                            label={<span>Fritext</span>} />

                        {freeTextsIndexes?.indexOf(ind) > -1 && <TextareaAutosize
                            id={`freeText_${ind}`}
                            placeholder={item?.placeholder ?? ""}
                            minRows={item?.minRows ?? 10}
                            name={name}
                            disabled={disabled}
                            autoComplete='off'
                            autoSave='off'
                            onChange={handleFreeTextsValueChange}
                            value={freeTexts ? freeTexts[name] : ""}
                            required />}
                    </div>}
                </div>}

                {/* DateTime input */}
                {inpType === "datetime" && <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker name={item?.name}
                            disabled={disabled}
                            ampm={false}
                            onChange={(e) => handleDateTimeChange(e, item?.name)} disablePast />
                    </DemoContainer>
                </LocalizationProvider>}

                {/* Children content form parent component */}
                {children && children}
            </div>

            {/* Number of question */}
            {(numbers && !hiddenLabel) && <label className="d-row question-number">
                <Numbers /> {number}
            </label>}
        </div>
    })
}

export default FormFields;
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

// Components
import FormButtons from './FormButtons';
import FormEmails from './FormEmails';
import Modal from './Modal';

// Services
import ApiRequest from '../services/ApiRequest';

// Functions
import { ErrorHandle } from '../functions/ErrorHandle';


function SendSurveyLink() {
    SendSurveyLink.displayName = "SendSurveyLink";


    const { id, navigate } = useOutletContext();


    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        formId: id
    });

    const onChange = (values) => {
        setFormData({
            ...formData, ...values
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await ApiRequest(`survey/send/${id}`, "post", formData).then(res => {
            setResponse(res);
        }, error => {
            setResponse(ErrorHandle(error, navigate));
            setLoading(false);
        })
    }

    const clickHandle = () => {
        if(!!response?.error)
            setResponse(null);
        else
            navigate(-1);
    }

    if (response)
        return <Modal error={response?.error} content={response?.msg} link={response?.link} close={clickHandle} />

    return (
        <form onSubmit={onSubmit} className="send-form fade-in w100 box-none">
            {/* Receivers */}
            <FormEmails label="Mottagare" type="email" update={onChange} />
            <FormButtons confirmation={true} value="Skicka" reset={() => navigate(-1)} loading={loading}
                disabled={formData?.emails?.length === 0 || (formData?.saveEmails && !formData?.emailsGroupName)} />
        </form>
    )
}

export default SendSurveyLink;
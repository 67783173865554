import { useEffect, useState } from "react";
import _ from "lodash";

// Installed
import { Close, HelpOutline, KeyboardReturn } from "@mui/icons-material";
import { Button, FormGroup, FormLabel, IconButton, TextField, Tooltip } from "@mui/material";

// Components

// Services
import ApiRequest from "../services/ApiRequest";
import FormFields from "./FormFields";

function FormEmails({ label, update }) {
  FormEmails.displayName = "FormVariantsField";

  const [value, setValue] = useState("");
  const [error, setError] = useState();
  const [emails, setEmails] = useState([]);
  const [variants, setVariants] = useState([]);

  const [formData, setFormData] = useState({
    emails: [],
    saveEmails: false,
    emailsGroupName: ""
  })

  const emailCheck = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
  const inputs = [
    { label: "Spara mottagare", name: "saveEmails", type: "checkbox", disabled: formData?.emails?.length === 0 || _.isEqual(formData?.emails, variants) },
    formData?.saveEmails && {
      label: "Gruppnamn för dem nya e-postadresser", name: "emailsGroupName", type: "text", class: "blink-color",
      placeholder: "Minst 3 och max 30 tecken", required: true, min: 3, max: 30
    }
  ];

  useEffect(() => {
    getEmails();
  }, [])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError();
      }, 3000)
    }
  }, [error])

  useEffect(() => {
    update(formData);
  }, [update, formData]);


  async function getEmails() {
    await ApiRequest("form/receivers").then(res => {
      setEmails(res?.data);
    });
  }

  const setFromSaved = (e) => {
    let current = formData?.emails;
    let saved = emails?.filter(x => x?.groups?.indexOf(e.target.value) > -1).map(item => item?.email);
    setVariants(saved);
    setFormData({
      ...formData, emails: [...new Set(current.concat(saved))]
    });
  }

  const onFormChange = (value) => {
    setFormData({ ...formData, ...value });
  }

  const onFieldChange = (e, enter) => {
    let value = !!e ? e?.target?.value : enter;
    setError();
    const index = value?.indexOf(",");

    if (index > -1 || !!enter) {

      if (!emailCheck.test(value)) {
        setError("error")
        return;
      }

      if (index > -1)
        value = value.slice(0, index);

      let arr = formData?.emails ?? [];
      if (arr?.indexOf(value) > -1) {
        setError(value);
        setValue("");
        return;
      } else {
        arr.push(value);
        setValue("");
      }


      const corrected = arr.filter(x => emailCheck.test(x));
      setFormData({ ...formData, emails: corrected });

      sessionStorage.setItem("receivers", JSON.stringify(corrected));
    }
    else
      setValue(value);
  }

  const handleRemove = (index) => {
    if (!!error)
      setError();

    setTimeout(() => {
      setFormData({ ...formData, emails: formData.emails.filter((x, ind) => ind !== index) });
    }, 100)
  }

  return (
    <div className='d-column variants-container'>
      <FormLabel className="label d-row jc-between" error={!!error}>
        {label}
        <Tooltip title="Obs! Glöm inte att lägga till varje ny adress genom att klicka på Enter eller skriva kommatecken!" classes={{
          tooltip: "tooltip-info",
          arrow: "tooltip-arrow-info",
        }} arrow>
          <HelpOutline color="info" />
        </Tooltip>
      </FormLabel>

      {/* Variants field */}
      <div className="d-row jc-between variants-fields box-none">
        <TextField
          name="variants"
          type="email"
          value={value}
          className="form-control"
          onKeyUp={(e) => {
            if (e.key === "Enter")
              onFieldChange(e, true);
          }}
          inputProps={{
            maxLength: 200
          }}
          error={!!error}
          onChange={onFieldChange}
          placeholder={!!error ? `En liknande ${label?.toLowerCase()} finns redan i listan.`
            : "Separera med kommatecken eller tryck Enter"}
          InputProps={{
            endAdornment: value.slice(value.indexOf("@"))?.length > 3 ? <div className='d-row'>
              <IconButton onClick={() => setValue()}><Close /></IconButton>
              <IconButton onClick={() => onFieldChange(null, value)}><KeyboardReturn /></IconButton>
            </div> : null
          }}
        />

        {/* Dropdown list */}
        <FormFields inputs={[{
          name: "receiver", type: "list", placeholder: "Sparade e-postadresser", disabled: emails?.length === 0,
          variants: [...new Set(emails?.map(x => x?.groups[0]))]
        }]} initData={{ receiver: "" }} onValueChange={setFromSaved} />
      </div>

      {/* Variants list */}
      {formData?.emails?.length > 0 && <FormGroup className="variants-wrapper variants-list-wrapper box-none">
        {/* List */}
        {formData?.emails?.map((item, ind) => {
          return <Button key={ind}
            id={ind}
            className="d-row"
            variant="outlined"
            color={error === item ? "error" : "primary"}>
            {item}  <Close onClick={() => handleRemove(ind)} />
          </Button>
        })}
      </FormGroup>}


      {/* Form */}
      <div className="w100 box-none">
        <FormFields inputs={inputs} initData={formData} onFormChange={onFormChange} />
      </div>
    </div>
  )
}

export default FormEmails;
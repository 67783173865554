// Components
import Modal from './Modal';

function ExpiredSession({ navigate }) {
    ExpiredSession.displayName = "ExpiredSession";

    const closeModal = () => {
        navigate("/logout");
    }

    return (
        <Modal 
            error={true}
            msg="Sessionen har löpt ut"
            content="Var vänlig och logga in igen."
            close={closeModal} />
    )
}

export default ExpiredSession;
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// Installed
import { DataGrid } from "@mui/x-data-grid";
import { Archive, Cable, Cancel, Check, ChromeReaderMode, CopyAll, Download, Edit, ForwardToInbox, Lock, LockOpen, MenuOutlined, MoveUpSharp, Send, Settings, Timer, Troubleshoot, ViewTimeline, Warning } from "@mui/icons-material";
import { Alert, IconButton, List as ListMenu, ListItemIcon, ListItemText, Tooltip, ListItem } from "@mui/material";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

// Components
import Container from "./Container";
import LinearLoading from "./LinearLoading";
import Modal from "./Modal";
import Loading from "./Loading";

// Functions
import { ErrorHandle } from "../functions/ErrorHandle";
import { CheckNumber } from "../functions/CheckNumber";

// Services
import ApiRequest from "../services/ApiRequest";


function ListView({ api, hidden }) {
    ListView.displayName = "ListView";

    const [sort, setSort] = useState("");
    const [initList, setInitList] = useState([]);
    const [list, setList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [action, setAction] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState();
    const [rowId, setRowId] = useState();
    const [categories, setCategories] = useState();

    const { item, url, update, param, category, expandHandle, navigate, getCategory } = useOutletContext();
    const keywords = {
        surveys: ["warning", "copy", "move", "cogs", "connected", "info", "view", "send", "edit", "delete"],
        active: ["cogs", "overview", "remind", "deadline", "lock", "archive", "delete"],
        closed: ["cogs", "overview", "open", "lock-open", "archive", "delete"],
        archive: ["overview", "delete"],
        suppliers: ["delete"],
        participants: ["delete"],
        questions: ["view", "connected", "delete"],
        logs: ["download", "delete"]
    }

    // Columns content
    let columns = [
        {
            field: 'id', headerName: "#", sortable: false, width: 100, headerAlign: "center",
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1
        },
        {
            field: item?.name ?? 'name', headerName: "Namn", flex: 1, sortable: false, headerClass: "name-row",

            renderCell: (params) => {
                const row = params.row;

                if (!!row.viewName)
                    return <p className="d-column ai-start jc-start dots" dangerouslySetInnerHTML={{ __html: row.viewName }}></p>;

                return <p className="w100 dots">{row.name}</p>;
            }
        },
        {
            field: 'actions', headerName: "⚙️", sortable: false,
            filterable: false, headerAlign: "center", width: 230,
            disableColumnMenu: true, disableColumnSelector: true, disableColumnFilter: true,
            renderCell: (params) => {
                const id = params.id;
                const row = params.row;
                const pathname = item?.url;
                const used = row?.suppliers?.length > 0;
                const result = row?.receivers?.result ?? 0;

                // e.stopPropagation();
                let buttons = !!item ? [
                    !row?.name && {
                        icon: <Warning color="error" />, name: "warning", error: true,
                        msg: "Enkät titel saknas!", disabled: false
                    },
                    !!row?.hidden && {
                        icon: <Settings color="" />, name: "cogs",
                        msg: "Testartikeln", disabled: false
                    },
                    {
                        icon: <Cable color="warning" />, name: "connected",
                        msg: "Används i en eller flera pågående undersökningar", disabled: !row?.connected
                    },
                    {
                        icon: <ChromeReaderMode color={used ? "info" : "inherit"} />, name: "info",
                        msg: <span dangerouslySetInnerHTML={{ __html: `Valda leverantörer<br/><br/><span class="tooltip-span"> - ${row?.suppliers?.join("<br/> - ")}</span>` }}></span>, disabled: !used
                    },
                    {
                        icon: <Timer color="secondary" />, name: "deadline",
                        msg: `Deadline: ${row.deadlineTime}`, disabled: row?.closed
                    },
                    {
                        icon: action === params?.id ? <Check color="success" /> : <ForwardToInbox color="primary" />, name: "remind",
                        function: () => clickHandle({ msg: `Skicka påminnelse till ${row?.receivers?.participants?.length - row?.receivers?.responded?.length} delatagre?`, run: sendRemindMail, id: id }),
                        msg: "Skicka påminnelse", disabled: result === 100
                    },
                    {
                        icon: <ViewTimeline color="secondary" />, name: "view", function: () => navigate(row?.link ?? `/view/${pathname}/${id}`),
                        msg: "Visa", disabled: !row?.name || !!row.disabled
                    },
                    {
                        icon: <Send color="success" />, name: "send", function: () => navigate(`/send/${pathname}/${id}${row?.hidden ? "/form" : ""}`),
                        msg: "Skicka", disabled: !row?.name
                    },
                    {
                        icon: <CopyAll color="info" />, name: "copy", function: () => clickHandle({ msg: "Kopiera?", run: copySurvey, id: id }),
                        msg: "Kopiera", disabled: false
                    },
                    {
                        icon: <MoveUpSharp color="warning" />, name: "move", function: () => clickHandle({ label: "Väj kategori?", children: true, row: row, id: id }),
                        msg: "Byta kategori", disabled: false
                    },
                    {
                        icon: <Troubleshoot color="info" />, name: "overview", function: () => navigate(`/categories/${category?.keyword}/survey/${param === "archive" ? "archive/" : ""}${id}`),
                        msg: "Mer information", disabled: false
                    },
                    {
                        icon: <Archive color="disabled" />, name: "archive", function: () => clickHandle({ msg: "Flytta till arkiv?", run: sendToArchive, id: id }),
                        msg: "Skicka till arkiv", disabled: (result < 100 && !row.closed)
                    },
                    {
                        icon: <Lock color="warning" />, name: "lock", function: () => clickHandle({ msg: "Stänga?", run: closeOpenSurvey, row: row, id: id }),
                        msg: "Stäng enkät", disabled: false
                    },
                    {
                        icon: <LockOpen color="success" />, name: "lock-open", function: () => clickHandle({ msg: "Öppna?", run: closeOpenSurvey, row: row, id: id }),
                        msg: "Öppna enkät", disabled: result === 100
                    },
                    {
                        icon: action === params?.id ? <Check color="success" /> : <Download color="primary" />, name: "download", function: () => clickHandle({ msg: "Ladda ner?", run: downloadFile, id: id }),
                        msg: "Ladda ner filen", disabled: false
                    },
                    {
                        icon: <Edit color="primary" />, name: "edit", function: () => navigate(used ? `/edit/${pathname}/${id}/used` : `/edit/${pathname}/${id}`),
                        msg: "Redigera", disabled: row?.connected
                    },
                    { icon: <Cancel color="error" />, name: "delete", function: () => clickHandle({ msg: "Radera?", run: deleteItem, id: id }), msg: "Radera", disabled: row?.used !== undefined ? row?.used : row?.connected }
                ].map((x) => {
                    if (keywords[item?.link]?.indexOf(x?.name) > -1)
                        return x;
                    return null;
                }) : [];

                return <div className='row-buttons d-row'>
                    {(action?.id !== id) ?
                        <>
                            {buttons.map((b, i) => {
                                if (!b || !!b.function) return null;

                                return <IconButton key={i} disabled={b?.disabled}>
                                    <Tooltip classes={{
                                        tooltip: `tooltip-${b?.error ? "red" : "white"}`,
                                        arrow: `tooltip-arrow-${b?.error ? "red" : "white"}`
                                    }} title={b.msg} arrow>
                                        {b.icon}
                                    </Tooltip>
                                </IconButton>
                            })}

                            <IconButton onClick={(e) => handleMenu(row?.id)}>
                                <MenuOutlined />
                            </IconButton>
                            {rowId === row?.id && <ClickAwayListener onClickAway={() => setRowId()}>
                                <ListMenu className="row-menu d-column swing-in-right-bck">
                                    {buttons.filter(x => !x?.disabled).map((b, i) => {
                                        if (!b || !b.function) return null;

                                        return <ListItem key={i} onClick={b.function} >
                                            <ListItemIcon>{b.icon}</ListItemIcon>
                                            <ListItemText>{b.msg}</ListItemText>
                                        </ListItem>
                                    })}
                                </ListMenu>
                            </ClickAwayListener>}
                        </> : <Loading size={20} />}
                </div>
            }
        }
    ];

    // Extra fields
    if (item?.fields !== "none") {
        const props = { headerAlign: "center", type: 'number', width: 200 }
        if (item?.fields?.length > 0) {
            item.fields?.map((field, ind) => {
                return columns.splice(2, 0, {
                    ...{
                        field: field,
                        headerName: item?.fieldNames[ind]
                    }, ...{ props }
                });
            })
        } else {
            columns.splice(2, 0, {
                ...{
                    field: "questionsCount",
                    headerName: "Frågor"
                }, ...{ props }
            });
        }

        if (item?.api.indexOf("survey") > -1) {
            columns.splice(3, 0, {
                field: 'result', headerName: "Genomförd", headerAlign: "center", type: 'number', sortable: false, width: 120,
                renderCell: (params) => {
                    return CheckNumber(params.row?.receivers?.result) + "%";
                }
            });
        }
    }

    if (!!item?.replace) {
        let index = columns.findIndex(x => x.headerName === "Namn");
        if (index > -1)
            columns[index].headerName = item?.replace;
    }

    useEffect(() => {
        setList([]);
        setError();
    }, [api, category])

    useEffect(() => {

        if (!!item?.api || (!!item?.api && update))
            getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    useEffect(() => {
        const key = sort?.toLowerCase()
        setList(initList.filter(x => x.name?.toLowerCase().includes(key) || x[item?.checkParam]?.toLowerCase().includes(key)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort])

    const getList = async () => {
        setLoading(true);

        await ApiRequest(`${item?.api ?? api}`).then(res => {
            setInitList(res?.data?.list ?? res?.data);
            setList(res?.data?.list ?? res?.data);

            setLoading(false);

            if (res?.data?.value)
                setValue(res?.data?.value);
            else
                setValue();

            getCategories();

        }, error => errorHandle(error));
    }

    const getCategories = async () => {
        await ApiRequest("category").then(res => {
            setCategories(res.data);
        })
    }

    const clickHandle = (props) => {
        const id = action?.id ?? props?.id;

        setRowId(null);
        const obj = !!props?.run ? {
            label: list.find(x => x.id === id)?.name,
            props: {
                buttonValue: "Ja",
                clickHandle: () => props?.run(props?.row ?? props?.id)
            },
            row: action?.row ?? props?.row
        } : { label: props?.label };

        setAction({ ...obj, ...props })
    }

    const handleMenu = (id) => {
        setRowId(rowId === id ? null : id);
    }

    const downloadFile = async (id) => {
        const fileDownload = require('react-file-download');
        await ApiRequest(`logs/download/${id}`).then(res => {
            if (res.status === 200) {
                setAction({
                    label: "",
                    close: true
                });
                fileDownload(res.data?.logText, res.data?.fileName);
            } else {
                setError(res?.data?.message)
                setAction();
            }
        }, error => errorHandle(error));
    }

    const sendToArchive = async (id) => {
        await ApiRequest(`survey/move/${id}`, "delete").then(res => {
            responseHandle(res, id);
        }, error => errorHandle(error));
    }

    const closeOpenSurvey = async (item) => {
        const id = item?.id;
        await ApiRequest(`survey/lock/${id}`, 'put', item).then(res => {
            responseHandle(res, id);
        }, error => errorHandle(error));
    }

    const sendRemindMail = async (id) => {
        await ApiRequest(`survey/remind/mails/${id}`).then(res => {
            if (res.status === 200 && !res.data) {
                setAction(id);
                setTimeout(() => { setAction(); }, 3000);
            } else {
                setError(res?.data?.message)
                setAction();
            }
        }, error => errorHandle(error));
    }

    const copySurvey = async (id) => {
        await ApiRequest(`form/copy/${id}`).then(res => {
            responseHandle(res);
        }, error => errorHandle(error));
    }

    const changeCategory = async (id) => {
        const ctg = categories.find(x => x.id === id);

        var category = {
            id: ctg?.id,
            name: ctg?.name,
            keyword: ctg?.keyword
        }

        await ApiRequest(`form/switch/category/${action?.row?.id}`, "put", category).then(res => {
            responseHandle(res, action?.row?.id);
        }, error => errorHandle(error));
    }

    const refresh = async () => {
        await ApiRequest("supplier/update").then(res => {
            if (res.status === 200 && !res.data)
                getList();
            else
                errorHandle(res.data);
        }, error => errorHandle(error));
    }

    const deleteItem = async (id) => {
        await ApiRequest(`${(!param ? api ?? item?.api : `${api}/${param}`)}/${id}`, "delete").then(res => {
            responseHandle(res, id);
            setList([]);
            getList();
        }, error => errorHandle(error));
    }

    const responseHandle = (res, id = null) => {
        setAction();
        if (res.status === 200 && !res.data) {
            if (!!id)
                setList(list.filter(x => x.id !== id));
            else
                getList();

            if (!!getCategory)
                getCategory();
        } else if (!!id)
            errorHandle(res.data);
    }

    const errorHandle = (error) => {
        setLoading(false);
        setAction();
        setError(ErrorHandle(error, navigate)?.msg);
    }

    return (
        <Container label={!value ? item?.label : `${item?.label}<br/><span style="font-size: 12px;">${value}</span>`}
            sort={sort}
            url={url}
            hidden={hidden || !url}
            update={!!item?.update ? refresh : undefined}
            sorting={(value) => setSort(value)}
            expand={expandHandle}>

            {/* Error */}
            {!!error && <Alert variant="standard" severity="error" sx={{
                marginBottom: "15px"
            }} onClose={() => setError(null)}><span dangerouslySetInnerHTML={{ __html: error }}></span></Alert>}

            {/* List */}
            <DataGrid
                rows={loading ? [] : list}
                columns={!item?.date ? columns.filter(x => x.field !== "date") : columns}
                className='content-list'
                pageSizeOptions={[15, 25, 50, 100, list.length]}
                disableRowSelectionOnClick
                // checkboxSelection
                // isRowSelectable={false}
                // isRowSelectable={(params) => params.row.quantity > 10000}
                pageSize={15}
                rowsPerPageOptions={[15]}
                density='comfortable'
                sx={{
                    display: "flex",
                    justifyContent: "stretch",
                    '& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeadersInner:hover .MuiSvgIcon-root, .MuiDataGrid-columnHeadersInner .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                        color: "#FFFFFF",
                        border: 0
                    },
                    '& .MuiDataGrid-columnHeadersInner': {
                        width: "100%",
                        backgroundColor: "#4a4b4b",
                        textAlign: "center"
                    },
                    '& .MuiDataGrid-columnHeader': {
                        justifyContent: "center",
                        fontSize: "18px"
                    },
                    '& .MuiDataGrid-columnHeader[data-field="actions"]': {
                        color: "#FFFFFF",
                        fontSize: "20px"
                    },
                    '& .MuiDataGrid-row': {
                        backgroundColor: "#FFFFFF",
                        pointerEvents: "auto",
                        flex: 1
                    },
                    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                        margin: "auto"
                    },
                    '& .MuiDataGrid-columnHeader[data-field="name"] .MuiDataGrid-columnHeaderTitleContainerContent': {
                        margin: 0
                    },
                    '& .MuiDataGrid-cell': {
                        padding: 0,
                        justifyContent: "center"
                    },
                    '& .MuiDataGrid-cell[data-field="name"]': {
                        justifyContent: "flex-start"
                    },
                    '& .MuiDataGrid-withBorderColor': {
                        outlineWidth: "0 !important"
                    }
                }}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 15,
                        },
                    },
                }}
                onRowSelectionModelChange={(ids) => {
                    setSelectedRows(ids);
                }}
                // checkboxSelection={true}
                rowSelectionModel={selectedRows}
                loading={loading}
                slots={{
                    loadingOverlay: LinearLoading,
                }}
                localeText={{
                    noRowsLabel: loading ? "" : `Ingen ${item?.name?.toLowerCase()} hittades`
                }}
                rowCount={list.length}
                experimentalFeatures={{
                    lazyLoading: true,
                }}
            />

            {/* Modal */}
            {!!action && <Modal
                {...action?.props}
                msg={action?.label}
                content={action?.msg}
                closeButtonValue={action?.close ? null : "Avbryta"}
                close={() => setAction()}>
                {!!action?.children && <ListMenu className="modal-list">
                    {categories?.map((c, ind) => {
                        if (c.id === category?.id)
                            return null;

                        return <ListItem key={ind} onClick={() => clickHandle({
                            msg: `Flytta till ${c?.name}?`, run: changeCategory, id: c.id
                        })}>
                            <ListItemText primary={c.name} />
                        </ListItem>
                    })}
                </ListMenu>}
            </Modal>}
        </Container>
    )
}

export default ListView

import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Installed
import { useMsal } from "@azure/msal-react";

// Components
import Loading from "../../components/Loading";
import ExpiredSession from "../../components/ExpiredSession";

// Services
import ApiRequest from "../../services/ApiRequest";
import { AuthContext } from "../../services/AuthContext";

// Storage
import { config } from "../../storage/Config";

function Logout({ expired }) {

    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const { instance } = useMsal();

    const logoutRequest = {
        account: instance.getAccountByHomeId(config.auth.clientId),
        mainWindowRedirectUri: config.auth.redirectUri
    };

    useEffect(() => {
        document.title = "Utloggning";
        const token = localStorage.getItem("token") || sessionStorage.getItem("token");
        async function logout() {
            await ApiRequest("authentication/logout/" + token).then(res => {
                if (res.data) {
                    authContext.logout();
                    instance.logoutRedirect(logoutRequest).catch((e) => {
                        console.error(e);
                    });

                    // navigate("/");
                }
            })
        }

        if (!expired)
            logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expired])

    if (!!expired)
        return <ExpiredSession navigate={navigate} />;

    return (
        <Loading msg="Du loggas ut" color="inherit" size={50} />
    )
}

export default Logout
// Installed
import { CircularProgress } from "@mui/material";

function Loading({ msg, color = "inherit", size = 30, style }) {
  Loading.displayName = "Loading";

  return (
    <div className='wrapper d-column' style={style ?? null}>
      <CircularProgress size={size} color={color} />
      {msg && <p className="loading-message">{msg}</p>}
    </div>
  )
}
export default Loading;